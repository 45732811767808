import { BillOfLading } from '@/models/BillOfLading'
import { CargoProject } from '@/models/CargoProject'
import { ClientGroup } from './ClientGroup'
import { Country } from '@/models/Country'
import { FlatTransport } from '@/models/FlatTransport'
import { GeographicAreas } from '@/models/GeographicAreas'
import { Model } from '@/models/Model'
import { Offer } from '@/models/Offer'
import { PhoneCountryCode } from '@/models/PhoneCountryCode'
import { PluralResponse } from 'coloquent/dist/response/PluralResponse'
import { Port } from '@/models/Port'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import User from '@/models/User'
import { Vessel } from '@/models/Vessel'

export class Company extends Model {
	protected jsonApiType = 'companies'
	protected static pageSize = 10

	public ports(): ToManyRelation {
		return this.hasMany(Port, 'ports')
	}

	public getPorts(): Array<Port> {
		return this.relation('ports') || []
	}

	public country(): ToOneRelation {
		return this.hasOne(Country, 'country')
	}

	public getCountry(): Country | null {
		return this.getRelation('country') || null
	}

	public clients(): ToManyRelation {
		return this.hasMany(Company, 'clients')
	}

	public getClients(): Array<Company> {
		return this.getRelation('clients') || []
	}

	public clientGroups(): ToManyRelation {
		return this.hasMany(ClientGroup, 'clientGroups')
	}

	public getClientGroups(): Array<ClientGroup> {
		return this.getRelation('clientGroups') || []
	}

	public brokers(): ToManyRelation {
		return this.hasMany(Company, 'brokers')
	}

	public getBrokers(): Array<Company> {
		return this.getRelation('brokers') || []
	}

	public users(): ToManyRelation {
		return this.hasMany(User, 'users')
	}

	public vessels(): ToManyRelation {
		return this.hasMany(Vessel, 'vessels')
	}

	public geographicAreas(): ToManyRelation {
		return this.hasMany(GeographicAreas, 'geographicAreas')
	}

	public offers(): ToManyRelation {
		return this.hasMany(Offer, 'offers')
	}

	public cargoProjects(): ToManyRelation {
		return this.hasMany(CargoProject, 'cargoProjects')
	}

	public externalTransports(): ToManyRelation {
		return this.hasMany(FlatTransport, 'externalTransports')
	}

	public getUsers(): Array<User> {
		return this.getRelation('users') || []
	}

	public invoiceCountry(): ToOneRelation {
		return this.hasOne(Country, 'invoiceCountry')
	}

	public getInvoiceCountry(): Country | null {
		return this.getRelation('invoiceCountry') || null
	}

	public mailCountry(): ToOneRelation {
		return this.hasOne(Country, 'mailCountry')
	}

	public getMailCountry(): Country | null {
		return this.getRelation('mailCountry') || null
	}

	public primaryPhoneCountryCode(): ToOneRelation {
		return this.hasOne(PhoneCountryCode, 'primaryPhoneCountryCode')
	}

	public getPrimaryPhoneCountryCode(): PhoneCountryCode | null {
		return this.getRelation('primaryPhoneCountryCode') || null
	}

	public secondaryPhoneCountryCode(): ToOneRelation {
		return this.hasOne(PhoneCountryCode, 'secondaryPhoneCountryCode')
	}

	public getSecondaryPhoneCountryCode(): PhoneCountryCode | null {
		return this.getRelation('secondaryPhoneCountryCode') || null
	}

	public tertiaryPhoneCountryCode(): ToOneRelation {
		return this.hasOne(PhoneCountryCode, 'tertiaryPhoneCountryCode')
	}

	public getTertiaryPhoneCountryCode(): PhoneCountryCode | null {
		return this.getRelation('tertiaryPhoneCountryCode') || null
	}

	public setCountry(country: Country | null): void {
		this.setRelation('country', country)
	}

	public setInvoiceCountry(country: Country | null): void {
		this.setRelation('invoiceCountry', country)
	}

	public setMailCountry(country: Country | null): void {
		this.setRelation('mailCountry', country)
	}

	public setPrimaryPhoneCountryCode(phoneCountryCode: PhoneCountryCode | null): void {
		this.setRelation('primaryPhoneCountryCode', phoneCountryCode)
	}

	public setSecondaryPhoneCountryCode(phoneCountryCode: PhoneCountryCode | null): void {
		this.setRelation('secondaryPhoneCountryCode', phoneCountryCode)
	}

	public setTertiaryPhoneCountryCode(phoneCountryCode: PhoneCountryCode | null): void {
		this.setRelation('tertiaryPhoneCountryCode', phoneCountryCode)
	}

	public frequentlyUsedBillOfLadingValues(): ToManyRelation {
		return this.hasMany(BillOfLading, 'frequentlyUsedBillOfLadingValues')
	}

	public getFrequentlyUsedBillOfLadingValues(): BillOfLading {
		return this.getRelation('frequentlyUsedBillOfLadingValues')
	}

	public latestLoadingBill(): ToOneRelation {
		return this.hasOne(BillOfLading, 'latestLoadingBill')
	}

	public get title(): string {
		let extraText = ''
		if (this.place) {
			extraText = `(${this.place})`
		}
		return `${this.name} ${extraText}`
	}

	@Property()
	public name: string | null | undefined

	@Property('logoPath')
	public logoUrl: string | undefined

	@Property()
	public address: string | null | undefined

	@Property()
	public postalCode: string | null | undefined

	@Property()
	public place: string | null | undefined

	@Property()
	public website: string | null | undefined

	@Property()
	public vatNumber: string | null | undefined

	@Property()
	public cocNumber: number | null | undefined

	@Property()
	public termOfPaymentDays!: number | null

	@Property()
	public note: string | null | undefined

	@Property()
	public iban: string | null | undefined

	@Property()
	public logo: string | null | undefined

	@Property()
	public disclaimer: string | null | undefined

	@Property()
	public phoneNumber: string | null | undefined

	@Property()
	public alternativePhoneNumber: string | null | undefined

	@Property()
	public secondAlternativePhoneNumber: string | null | undefined

	@Property()
	public receiveTextsOnPhoneNumber: boolean | undefined

	@Property()
	public receiveTextsOnAlternativePhone: boolean | undefined

	@Property()
	public receiveTextsOnSecondAlternativePhone: boolean | undefined

	@Property()
	public hasAlternativeInvoiceDetails: boolean | undefined

	@Property()
	public alternativeInvoiceEmail: string | null | undefined

	@Property()
	public alternativeInvoiceAddress: string | null | undefined

	@Property()
	public alternativeInvoicePostalCode: string | null | undefined

	@Property()
	public alternativeInvoicePlace: string | null | undefined

	@Property()
	public hasAlternativeMailDetails: boolean | null | undefined

	@Property()
	public alternativeMailAddress: string | null | undefined

	@Property()
	public alternativeMailPostalCode: string | null | undefined

	@Property()
	public alternativeMailPlace: string | null | undefined

	@Property()
	public firstName: string | undefined

	@Property()
	public lastName: string | undefined

	@Property()
	public email: string | undefined

	@Property()
	public primaryColor: string | undefined

	@Property()
	public secondaryColor: string | undefined

	@Property()
	public invoiceDocumentFooter: string | null | undefined

	@Property()
	public personalizedEmails: boolean | undefined

	@Property()
	public emailSignature: string | undefined

	@Property()
	public reference: string | undefined | null

	@Property()
	public relationTypes: string | null | undefined

	public static async fetchLazy(id): Promise<Company> {
		const store = (await import('@/store/index')).store
		if (!store.getters['companies/hasCached'](id)) {
			await store.dispatch('companies/findRecord', id)
		}

		return store.getters['companies/peekRecord'](id)
	}

	public static async fetchGeographicAreasLazy(id): Promise<Array<GeographicAreas>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['companies/hasRelationCached'](id, 'geographicAreas')) {
			const geographicAreasQuery = (await Company.fetchLazy(id)).geographicAreas().get()
			const geographicAreas = ((await geographicAreasQuery) as PluralResponse).getData() as Array<GeographicAreas>

			store.commit('companies/cacheRelationship', {
				id: id,
				relationName: 'geographicAreas',
				values: geographicAreas
			})
		}

		return store.getters['companies/peekRelation'](id, 'geographicAreas')
	}
}
